import React from "react";

import './clients.scss'

import logo_bitbucket from '../assets/logo_bitbucket.png';
import logo_jira from '../assets/logo_jira.png';
import logo_slack from '../assets/logo_slack.png';
import logo_microsoft from '../assets/logo_microsoft.svg';

const Clients = () => (
  <section className="clients01">
    <div className="container">
      <div className="row mb-3">
        <h2>Integrated with your tools</h2>
      </div>
      <div className="row">
        <ul className="clients01__list">
          <li className="clients01__item">
            <span className="clients01__img_box">
              <img loading="lazy" alt="Logo Bitbucket" className="clients01__logo" src={logo_bitbucket} height="28" />
            </span>
          </li>
          <li className="clients01__item">
            <span className="clients01__img_box">
              <img loading="lazy" alt="Logo Jira" className="clients01__logo" src={logo_jira} height="28" />
            </span>
          </li>
          <li className="clients01__item">
            <span className="clients01__img_box">
              <img loading="lazy" alt="Logo Slack" className="clients01__logo" src={logo_slack} height="24" />
            </span>
          </li>
          <li className="clients01__item">
            <span className="clients01__img_box">
              <img loading="lazy" alt="Logo Microsoft" className="clients01__logo" src={logo_microsoft} height="20" />
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

export default Clients
