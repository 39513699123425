import './features02.scss'

import logo from '../assets/onzen_diagram.png';

const Features02 = () => (
  <section className="features02">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 mx-auto text-center">
          <h2>Smart Engineering</h2>
          <p className="lead mb-0">Onzen is a Machine Learning powered product that integrates with your engineering workflow and detects behaviors that deviate from normal operating patterns and recommends actions to improve.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto pt-4">
          <img className="img-fluid center-block" alt="Onzen diagram" src={logo}></img>
        </div>
      </div>
    </div>

  </section>
)

export default Features02
