import React from "react";

import './showcase.scss'

const Showcase = () => (
  <section className="showcase">
    <div className="container p-0">
      <div className="row flex-lg-row-reverse align-items-center g-3 py-2">
        <div className="col-lg-6 text-white">
          <img src="https://ucarecdn.com/20598a49-ce14-4585-a34b-82a04b693046/frame-1-(1).png" alt="Frame" className="d-block mx-lg-auto img-fluid"></img>
        </div>
        <div className="col-lg-6 order-lg-1 my-auto showcase-text">
          <h2>Only what matters</h2>
          <p className="lead mb-0">
            Configure the areas you want to track and improve.
            Onzen will identify bottlenecks and follow up with the right audience
          </p>
        </div>
      </div>
      <div className="row align-items-center g-5 py-5">
        <div className="col-lg-6 text-white">
          <img src="https://ucarecdn.com/c3e183e7-3858-469b-9d9a-79832bc3177b/art-quiet.png" alt="Quiet" className="d-block mx-lg-auto img-fluid"></img>
        </div>
        <div className="col-lg-6 my-auto showcase-text">
          <h2>Build calmer engineering teams</h2>
          <p className="lead mb-0">
            Provide the right notifications to the right audience at the right time.
            No more micromanagement, no more endless group chat messages, etc.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Showcase
