import React, {useEffect} from "react";

import Home from './pages/home';
import HowItWorks from './pages/how-it-works';
import CaseStudies from './pages/case-studies';
import Pricing from './pages/pricing';
import Blog from './pages/blog';
import ReactGA from 'react-ga';

import './App.scss'

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";


function App() {

  useEffect(() => {
    ReactGA.initialize('UA-206160803-3');
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/how-it-works" component={HowItWorks} />
        <Route path="/case-studies" component={CaseStudies} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/blog" component={Blog} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
