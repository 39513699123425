
import React from "react"
import Footer01 from "./footer01"
import Navbar from "./navbar"

const Layout = ({ children }) => (
  <>
    <Navbar
      title="Title"
      location="{location}"
    />
    {children}
    <Footer01
      title="Title"
      author="Author"
    />
  </>
)

export default Layout