import React from "react";
import { Form } from "./form";

import logo from '../assets/art_infographic.png';
import './header01.scss'

const Header01 = () => (
  <header className="header01">
    <div className="container text-center">
      <div className="row">
        <h1 className="display-3 fw-bold">
          Build <span className='text-underline-warning'>AI-driven</span> engineering teams
        </h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            Onzen helps engineering teams to improve speed and efficiency
            by providing automatic ML-based recommendations to the right audience at the right time.
          </p>
          <div className="mb-4">
            <Form />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="mx-auto">
          <img className="header01__img img-fluid center-block" alt="Analytics" src={logo}></img>
        </div>
      </div>
    </div>
  </header>
)

export default Header01
