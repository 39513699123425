
import Layout from '../components/layout'
import Pricing from '../components/pricing'

const PricingPage = () => (
  <Layout>
    <div className="container">
      <h1>Pricing</h1>

      <Pricing />
    </div>
  </Layout>
);

export default PricingPage;
