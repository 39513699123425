// import { Link } from "react-router-dom";

import logo from '../assets/onzen_logo.png';

const CustomNavbar = ({ pageInfo }) => (
    <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm static-top border-bottom">
        <div className="container">
            <a className="navbar-brand" href="/">
                <img src={logo} alt="Logo" width="30" height="24" className="d-inline-block align-text-top" />
                <span className="ms-2">Onzen</span>
            </a>
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="nav justify-content-center mx-auto">
                    <li className="nav-item">
                        <Link to="/how-it-works" className="nav-link active">
                            How it works
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/case-studies" className="nav-link">
                            Case studies
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/pricing" className="nav-link">
                            Pricing
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/blog" className="nav-link">
                            Blog
                        </Link>
                    </li>
                </ul>
            </div> */}
        </div>
    </nav>
);

export default CustomNavbar
