import React from "react";

import './features01.scss'

const Features01 = () => (
  <section className="features01 bg-light text-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <div className="features01-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div className="features01-icon d-flex">
              <i className="bi-speedometer2 m-auto text-primary"></i>
            </div>
            <h3>Compentitive advantage</h3>
            <p className="lead mb-0">
              of companies say that it is important to their company to develop and put new software into production quickly.
              </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="features01-item mx-auto mb-5 mb-lg-0 mb-lg-3">
            <div className="features01-icon d-flex">
              <i className="bi-bar-chart-line m-auto text-primary"></i>
            </div>
            <h3>Future of DevOps</h3>
            <p className="lead mb-0">Using AI and machine learning to monitor and analyze data from every corner of an IT environment</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="features01-item mx-auto mb-0 mb-lg-3">
            <div className="features01-icon d-flex">
              <i className="bi-people m-auto text-primary"></i>
            </div>
            <h3>Scale engineering</h3>
            <p className="lead mb-0">Provide the right notifications to the right audience at the right time. No more micromanagement, no more endless group chat messages, etc.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Features01
