import React from "react";

const Footer01 = () => (
    <footer className="pt-4 py-md-4 pt-md-4 border-top bg-dark text-white mt-auto">
        <div className="container text-center">
            <p>© 2021 Onzen All rights reserved.</p>
            <p><a href="/#">Back to top</a></p>
        </div>
    </footer>
)

export default Footer01
