import React, { useState } from "react";
import ReactGA from 'react-ga';

const FormKit = () => {
  return (
    <>
      <script src="https://f.convertkit.com/ckjs/ck.5.js" />
      <form action="https://app.convertkit.com/forms/2577746/subscriptions" className="mb-2" method="post"
        data-sv-form="2577746" data-uid="706a2a5277" data-format="inline" data-version="5"
        data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:false,&quot;url&quot;:&quot;https://convertkit.com?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800">
        <div className="formkit-background"></div>
        <div data-style="minimal">
          <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
          <div data-element="fields" data-stacked="false" className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <div className="col-auto">
              <input className="form-control form-control-lg" name="email_address" aria-label="Email Address" placeholder="Email Address" required="" type="email" />
            </div>
            <div className="col-auto">
              <button data-element="submit" className="btn btn-lg btn-primary mb-3">
                <div className="formkit-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                </div><span className="">Get early access</span>
              </button>
            </div>
          </div>
          <div className="formkit-guarantee" data-element="guarantee">
            <p>We won't send you spam. Unsubscribe at any time.</p>
          </div>
        </div>
      </form>
    </>
  );
}


const Form = () => {

  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: 'Form',
      action: 'Signup'
    });
    setEmail("")
    setResponse("Success! Now check your email to confirm your subscription.")
  }

  return (
      <>
        {
          !response &&
          <form className="d-grid gap-2 d-sm-flex justify-content-sm-center" onSubmit={handleSubmit}>
            <div className="col-auto">
              <label htmlFor="email" className="visually-hidden">Email</label>
              <input type="email" className="form-control form-control-lg" id="email" onChange={e => setEmail(e.target.value)} value={email} placeholder="your@email.com" autoComplete="off" />
            </div>
            <div className="col-auto">
              <button type="submit" className="btn btn-lg btn-primary mb-3">Get early access</button>
            </div>
          </form>
        }
        <div className="form01__response">{response}</div>
        {/* <div className="form-note">
          <p>No credit card required.</p>
        </div> */}
      </>
    )
}

const FormAction = () => {
  return (
    <section className="bg-light text-center">
      <div id="signup" className="cta-sm">
        <div className="container-m text-center">
          <div className="cta-content">
            <h1>Want early access? Join our beta!</h1>
            <h4 className="mb-3">We will contact you to find out more</h4>
            <Form />
          </div>
        </div>
      </div>
    </section>
  )
}

export {
  Form,
  FormAction
}
