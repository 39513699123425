
import Layout from '../components/layout'

const BlogPage = () => (
    <Layout>
      <div className="container">
        Blog
      </div>

    </Layout>
);

export default BlogPage;