
import Layout from '../components/layout'

const HowItWorksPage = () => (
    <Layout>


    </Layout>
);

export default HowItWorksPage;