
import Layout from '../components/layout'

const CaseStudiesPage = () => (
    <Layout>
      <div className="container">
        Case Studies
      </div>

    </Layout>
);

export default CaseStudiesPage;