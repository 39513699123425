import Layout from '../components/layout'
import {FormAction} from '../components/form'
import Header01 from '../components/header01'
import Features01 from '../components/features01'
import Clients from '../components/clients'
import Showcase from '../components/showcase'
import Features02 from '../components/features02'

const Home = () => (
  <Layout>
    <Header01 />
    <Clients />
    <Features01 />
    <Features02 />
    <Showcase />
    <FormAction />
  </Layout>
);

export default Home;
